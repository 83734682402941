<template>
  <div class="modal-auth">
    <template v-if="mode === 'auth'">
      <h2 class="modal-auth__title">
        Вход в личный кабинет
      </h2>

      <login-form @success="$emit('success')" />

      <p class="modal-auth__link">
        Нет аккаунта?
        <a
          href="#"
          @click.prevent="mode = 'register'"
        >
          ЗАРЕГИСТРИРОВАТЬСЯ
        </a>
      </p>
      <p class="modal-auth__link">
        Забыли пароль?
        <a
          href="#"
          @click.prevent="mode = 'reset'"
        >
          ВОССТАНОВИТЬ ПАРОЛЬ
        </a>
      </p>
    </template>

    <template v-else-if="mode === 'register'">
      <h2 class="modal-auth__title">
        Регистрация
      </h2>

      <register-form @go-to="goTo" />

      <p class="modal-auth__link">
        Уже зарегистрированы?
        <a
          href="#"
          @click.prevent="mode = 'auth'"
        >
          ВОЙТИ В КАБИНЕТ
        </a>
      </p>
    </template>

    <template v-else-if="mode === 'reset'">
      <h2 class="modal-auth__title">
        Восстановление пароля
      </h2>

      <reset-form @go-to="goTo" />

      <p class="modal-auth__link">
        Вспомнили пароль?
        <a
          href="#"
          @click.prevent="mode = 'auth'"
        >
          ВОЙТИ В КАБИНЕТ
        </a>
      </p>
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';

import LoginForm from '@/components/auth/LoginForm.vue';
import RegisterForm from '@/components/auth/RegisterForm.vue';
import ResetForm from '@/components/auth/ResetForm.vue';

export default {
  components: {
    LoginForm,
    RegisterForm,
    ResetForm,
  },
  emits: ['success'],
  setup() {
    const mode = ref('auth');

    const goTo = (payload) => mode.value = payload;

    return { mode, goTo };
  },
};
</script>

<style lang="scss" scoped>
.modal-auth {
  position: fixed;
  top: 105px;
  right: 0;
  width: 440px;
  height: calc(100vh - 85px);
  backdrop-filter: blur(4px);
  z-index: 1000001;
  border-left: 1px solid $color_dark-70;
  padding: 40px;
  background-color: transparent;
  will-change: transform;
  backface-visibility: hidden;

  .page_scrolled &, .ismm & {
    background-color: $color_dark-70;
    backdrop-filter: none;
    border-top: 1px solid $color_dark-70;
    transition: background-color 0.45s 0.25s ease;
  }

  ._secondary & {
    top: 89px;
  }

  &__title {
    font-size: 40px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: -0.005em;
    color: $white;
    margin-bottom: 30px;
  }

  :deep &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #A0A2A6;
    margin-bottom: 20px;
  }

  :deep &__form {
    margin-bottom: 20px;
  }

  :deep &__form-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 30px;
  }

  &__link {
    display: block;
    width: fit-content;
    color: $white;
    font-size: 12px;
    line-height: 24px;

    a {
      color: $color_orange-50;
    }
  }
}

:deep .form-input {
  &__inner {
    &:before, &:after {
      display: none;
    }
  }

  &__field {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__label {
    top: 14px;
    color: #b2b2b2;

    &._fixed-label {
      display: none;
    }
  }

  &__error {
    display: none;
  }
}

:deep .form-check {
  &__box {
    border-color: $white;
  }

  &__text {
    color: $white;
    white-space: unset;
  }
}

:deep .button {
  &:disabled {
    pointer-events: none;
    background-color: $color_dark-40;
    user-select: none;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 639px) {
  .modal-auth {
    width: 100%;
    top: 65px !important;
    height: calc(100vh - 65px);
    background-color: $color_dark-70;
    backdrop-filter: none;
    border-top: 1px solid $color_dark-70;
    transition: background-color 0.45s 0.25s ease;

    &._secondary {
      background-color: $color_dark-70;
      backdrop-filter: none;
      border-top: 1px solid $color_dark-70;
      transition: background-color 0.45s 0.25s ease;
    }
  }
}
</style>
