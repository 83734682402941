<template>
  <div
    class="mobile-menu"
    :class="{ '_is-open': isOpenMenu }"
  >
    <ul
      class="mobile-menu__list"
      @click="onClick($event.target)"
    >
      <li
        v-for="(item, index) in mobilePanelList"
        :key="index"
        class="mobile-menu__item"
      >
        <router-link
          :to="item.to"
          class="mobile-menu__item-link"
        >
          <app-svg
            :id="item.icon"
            class="mobile-menu__item-icon"
          />
          <span class="mobile-menu__item-name">{{ item.name }}</span>
        </router-link>
      </li>

      <li class="mobile-menu__item _burger">
        <BurgerMenuIcon
          :is-mobile="true"
          :is-clicked="isOpenMenu"
          class="mobile-menu__item-icon"
        />
        <span class="mobile-menu__item-name">Меню</span>
      </li>
    </ul>

    <MobileMenuNav
      :nav-list="navList"
      :burger-list="burgerList"
      :services-list="servicesList"
      :is-open-menu="isOpenMenu"
      class="mobile-menu__menu"
      :class="{ '_is-open': isOpenMenu }"
      @link-clicked="onClick"
    />
  </div>
</template>

<script>
import BurgerMenuIcon from "./BurgerMenuIcon.vue";
import MobileMenuNav from "./MobileMenuNav.vue";

export default {
  name: "MobileMenu",
  components: { BurgerMenuIcon, MobileMenuNav },

  props: {
    navList: {
      type: Array,
      default: () => [],
    },
    burgerList: {
      type: Array,
      default: () => [],
    },
    servicesList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isOpenMenu: false,
      mobilePanelList: [
        { name: "Вторичная", icon: "mobile-icon_01", to: "/resale-estate" },
        // { name: "Новостройки", icon: "mobile-icon_02", to: "/new-estate" },
        { name: "Услуги", icon: "mobile-icon_03", to: "/services" },
      ],
    };
  },

  methods: {
    onClick(eventTarget) {
      const body = document.querySelector("body");

      if (eventTarget.closest("._burger")) {
        body.classList.toggle("_overflow-hidden");
        this.isOpenMenu = !this.isOpenMenu;
      }
      if (eventTarget.closest(".mobile-menu__item-link") || eventTarget.closest(".mobile-menu-nav__go")) {
        body.classList.remove("_overflow-hidden");
        this.isOpenMenu = false;
      }

      document.querySelector(".header__logo").addEventListener("click", () => {
        body.classList.remove("_overflow-hidden");
        this.isOpenMenu = false;
      });
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  $that: &;

  &._is-open {
    & + .layout__header {
      background-color: $color_dark-90;
      box-shadow: 0px -7px 20px rgba(0, 0, 0, 0.35) !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.mobile-menu {
  $that: &;

  display: none;
  width: 100%;
  height: 55px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: $color_dark-90;
  box-shadow: 0px -7px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  @include tablet {
    display: block;
  }

  &._is-open {
    & + .layout__header {
      background-color: red !important;
      box-shadow: 0px -7px 20px rgba(0, 0, 0, 0.35) !important;
    }
  }

  &__list {
    position: relative;
    z-index: 2;
    display: flex;

    #{$that}._is-open & {
      background-color: $color_dark-90;
    }
  }

  &__item {
    // flex: 0 0 25%;
    flex: 1;
    color: $color_dark-40;
    padding: 8px;
    transition: color 0.25s ease-out;

    &._burger {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 68.5%), rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }

    &:hover {
      color: $white;
    }

    @include flex-center;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item-icon {
    box-sizing: content-box;
  }

  &__item-icon:not(._burger) {
    max-width: 20px;
    max-height: 20px;
    margin: auto;
  }

  &__item-name {
    font-family: "Montserrat", sans-serif;
    font-size: 9px;
    line-height: 16px;

    ._burger & {
      color: $white;
    }
  }

  &__menu {
    z-index: 1;
  }
}
</style>
